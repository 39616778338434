/* .App {
  text-align: center;
}

.App-logo {
  height: 10vmin;
  margin-right: 1vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 10vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-main {
  background-color: #042056; 
  min-height: 90vh;  
  display: flex;

  justify-content: center;  
  color: white;
}

.App-link {
  color: #61dafb;
} */

.active-bold {
  font-size: 18px;
  font-weight: bold !important;
}

.custom-login-btn {
  padding: 0.25rem 0.5rem; /* 패딩 조정 */
  font-size: 0.875rem; /* 글꼴 크기 조정 */
  line-height: 1; /* 라인 높이 조정 */
  height: auto; /* 높이 자동 조정 */
  border-radius: 0.2rem; /* 테두리 둥글기 조정 */
}
