body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* RESETS */
/* *,
*::before,
*::after {
  box-sizing: border-box;
} */
/* *:focus {
  outline: 3px dashed #228bec;
  outline-offset: 0;
}
html {
  font: 62.5% / 1.15 sans-serif;
} */

.bordered {
  border: 0.5px solid #000; /* 검은색 경계선 */
}

.bordered-container {
  border: 0.5px solid #000; /* 검은색 경계선 */
  padding: 10px;
}

.mainSmallText {
  font-size: 12px;
  /* color: '#291B44' */
}

.mainDefaultText {
  font-size: 16px;
  /* color: '#291B44' */
}

.mainInfoText {
  font-size: 16px;
  /* color: '#291B44' */
}

.mainInfoTextB {
  font-size: 16px;
  font-weight: bold;
  /* color: '#291B44' */
}

.mainTitleText {
  font-size: 20px;
  font-weight: bold;
  /* color: '#291B44' */
}

.mainDisableText {
  color: #BFBCB3;
}

.labelText {
  font-size: 16px;
  color: #fefefe;
}

.alertText {
  font-weight: bold;
  color: #fb3c67;
}

.emphasisText {
  font-weight: bold;
  color: #0000CD;
}

.flex_row {
  display: flex;
  align-items: center;
}

.flex_center {
  display: flex;
  align-items: center;  
  justify-content: center;
}

.flex_right {
  display: flex; 
  justify-content: flex-end;
}

.dark_mode {
  background-color: #343a40; /* 다크 배경색 */
  color: #ffffff; /* 글자색 */  
}

.space_between {
  display: flex;
  align-items: center;  
  justify-content: space-between;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #999; 
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #999;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #b8b4b4;
}
